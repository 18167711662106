/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
const PromiseLoader = function PromiseLoader() {
    /**
     * Promise-based script loader
     * @param {string} urlData
     * @param {object=} attr
     * @returns {Promise}
     */
    const loader = (urlData, attr) => new Promise((resolve, reject) => {
        const script = window.document.createElement('script');
        script.src = urlData.url;
        script.async = urlData.async;
        script.defer = urlData.defer;
        attr = attr || {};
        script.onError = reject;
        script.onload = resolve;

        for (const attrName in attr) {
            script[attrName] = attr[attrName];
        }

        if (urlData.module !== null && urlData.module === true) {
            script.type = 'module';
        } else if (urlData.module !== null && urlData.module === false) {
            script.setAttribute('nomodule', '');
        }

        script.addEventListener(
            'load',
            () => {
                resolve(script);
            },
            false
        );

        script.addEventListener(
            'error',
            () => {
                reject(script);
            },
            false
        );

        window.document.body.appendChild(script);
    });

    /**
     * Loads scripts asynchronously
     * @param {string|string[]} urlsData
     * @param {object=} attr Other script tag attributes
     * @returns {Promise}
     */
    this.load = (urlsData, attr) => {
        if (!Array.isArray(urlsData)) {
            urlsData = [urlsData];
        }

        return Promise.allSettled(urlsData.map((urlData) => loader(urlData, attr)));
    };

    /**
     * Loads scripts asynchronously. It supports multiple url arguments, so each one will be loaded right after the
     * previous is loaded. This is a way of chaining dependency loading.
     *
     * @param {string|string[]} urlsData, ...
     * @returns {Promise}
     */
    this.loadChain = function(urlsData) {
        const args = Array.isArray(arguments)
            ? arguments
            : Array.prototype.slice.call(arguments);
        const p = this.require(args.shift());
        const self = this;
        return args.length
            ? p.then(() => {
                self.requireChain(...args);
            })
            : p;
    };
};

export default function scriptLoader(urlsData, callback) {
    if (!Array.isArray(urlsData)) {
        throw new TypeError();
    }

    const loader = new PromiseLoader();
    loader.load(urlsData).then(() => {
        callback();
    });
}
