/* eslint-disable */
import observeIntersection from '../inc/observeIntersection';
import scriptLoader from '../inc/scriptLoader6m';

$(document).ready(() => {
    window.jQuery = jQuery;
    window.$ = jQuery;

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* 6m Components +++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* ALL Components +++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    if ($('.hotelOfferCards6m').length || $('.quickSearch6m').length || $('.offerMap6m').length || $('.banner6m').length) {
        let all6mSourcesLoaded = false;

        function init6mSources(entry, intersection) {
            if (all6mSourcesLoaded === false) {
                all6mSourcesLoaded = true;

                scriptLoader(
                    [
                        {
                            url: 'https://cloud.tui.com/ui/ui-elements/ui-elements/ui-elements.esm.js',
                            module: true,
                            async: true,
                            defer: false,
                        },
                        {
                            url: 'https://cloud.tui.com/ui/ui-elements/ui-elements/ui-elements.js',
                            module: false,
                            async: false,
                            defer: false,
                        },
                    ],
                    () => {}
                );
            }
        }

        observeIntersection('.offerMap6m,.banner6m,.hotelOfferCards6m,.quickSearch6m', init6mSources, true);
    }

    /* 6m OFFER CARDS && QUICK SEARCH +++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    if ($('.hotelOfferCards6m').length || $('.quickSearch6m').length) {
        let scriptCallbackLoaded = false;
        let scriptsLoaded = false;

        function initHotelOfferCards6m(entry, intersection) {
            if (scriptsLoaded === false && intersection === true) {
                console.log('initHotelOfferCards6m');
                scriptsLoaded = true;
                scriptLoader(
                    [
                        {
                            url: 'https://cloud.tui.com/cdn/hotel-offer-cards/current/hotel-offer-cards.js',
                            module: null,
                            async: true,
                            defer: true,
                        },
                        {
                            url: 'https://cloud.tui.com/6m/beone-content/hotel-summary-6m/master/tui-hotel-summary/tui-hotel-summary.esm.js',
                            module: true,
                            async: false,
                            defer: false,
                        },
                    ],
                    hotelOfferCards6mCallback
                );

                scriptLoader(
                    [
                        {
                            url: 'https://cloud.tui.com/6m/beone-content/hotel-summary-6m/master/tui-hotel-summary/tui-hotel-summary.js',
                            module: false,
                            async: false,
                            defer: false,
                        },
                    ],
                    hotelOfferCards6mCallback
                );
            }
        }

        const hotelOfferCards6mCallback = (entry, intersection) => {
            if (scriptCallbackLoaded === false) {
                scriptCallbackLoaded = true;
                console.log('hotelOfferCards6mCallback');
                // window.tuiCottonBall.subscribeToAllScopes('hotel-offer-cards', 'instance.rendered', function (component, scope, event, data) {
                //     const plattformUrl = 'https://tui.com/';
                //     generateCallToActionUrls(plattformUrl, data.searchParameters, data.hotelOffers, data.staticUrlParameters, data.setCallToActionUrls);
                // });

                window.tuiCottonBall.subscribeToAllScopes('hotel-offer-cards', 'callToAction.pushed', (component, scope, event, data) => {
                    const plattformUrl = 'https://tui.com/';
                    const offerObject = generateCallToActionUrl(plattformUrl, data.searchParameters, data.hotelOffer.hotel.giataId, data.hotelOffer.hotel.name, data.staticUrlParameters);
                    const urlParameter = $(`#${scope}`).attr('static-url-parameters');

                    tuiRedirect(offerObject.url + urlParameter);
                });

                window.tuiCottonBall.subscribeToAllScopes('hotel-offer-cards', 'information.touched', (component, scope, event, data) => {
                    const hotelInfoModal = document.querySelector('#hotelInfo');

                    if (hotelInfoModal) { var hotelSummary = document.createElement('tui-hotel-summary'); }
                    hotelSummary.setAttribute('giata-id', data.hotelOffer.hotel.giataId);
                    hotelSummary.setAttribute('hide-hotel-name', false);
                    hotelSummary.setAttribute('hide-group-headlines', false);
                    hotelSummary.setAttribute('hide-location', false);
                    hotelSummary.setAttribute('hide-room-carousel', false);
                    hotelSummary.setAttribute('hide-icons', false);
                    hotelSummary.setAttribute('collapsed', true);
                    hotelSummary.setAttribute('collapsible', true);
                    hotelSummary.setAttribute('auto-expand-first', true);
                    hotelSummary.setAttribute('hotel-group-headline-font-size', 'large');
                    hotelSummary.setAttribute('hotel-category-text-column-count', '1');

                    hotelInfoModal.querySelector('#hotelContent').appendChild(hotelSummary);
                    hotelInfoModal.style.display = 'block';
                    $('body').addClass('open');

                    const removeHotelInformation = () => {
                        const hotelSummaries = hotelInfoModal.getElementsByTagName('tui-hotel-summary');
                        for (let i = hotelSummaries.length - 1; i >= 0; --i) {
                            hotelSummaries[i].remove();
                        }
                    };
                    const closebutton = document.getElementsByClassName('close')[0];
                    closebutton.onclick = function() {
                        removeHotelInformation();
                        hotelInfoModal.style.display = 'none';
                        $('body').removeClass('open');
                    };

                    // When the user clicks anywhere outside of the modal, close it
                    window.onclick = function(event) {
                        if (event.target == hotelInfoModal) {
                            removeHotelInformation();
                            hotelInfoModal.style.display = 'none';
                            $('body').removeClass('open');
                        }
                    };
                });

                $('.tuiSearchFormDummy').remove();
                $('.tuiSearchForm').removeClass('hide');
            }
            window.dispatchEvent(new CustomEvent('6m:hotelOfferCardsIsLoaded'));
            window.dispatchEvent(new CustomEvent('6m:quicksearchIsLoaded'));
        };
        
        observeIntersection('.hotelOfferCards6m, .quickSearch6m', initHotelOfferCards6m, true);
    }

    /* 6m OFFER MAP  +++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    if ($('.offerMap6m').length) {
        let offerMap6mLoaded = false;

        function initOfferMap6m(entry, intersection) {
            if (offerMap6mLoaded === false && intersection === true) {
                offerMap6mLoaded = true;

                scriptLoader(
                    [
                        {
                            url: 'https://cloud.tui.com/cdn/offer-map/offer-map.js',
                            module: null,
                            async: false,
                            defer: false,
                        },
                    ],
                    () => {
                        window.tuiCottonBall.subscribeToAllScopes('offer-map', 'accommodation.clicked', (component, scope, event, data) => {
                            const plattformUrl = 'https://tui.com/';
                            const accommodationObject = generateCallToActionUrl(plattformUrl, data.searchParameter, data.giataId, data.name, data.staticUrlParameters);
                            const urlParameter = $(`#${scope}`).attr('static-url-parameters');

                            tuiRedirect(accommodationObject.url + urlParameter);
                        });
                    }
                );
            }
        }

        observeIntersection('.offerMap6m', initOfferMap6m, true);
    }
    
    /* 6m FUNCTIONS +++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    // TUI Link Redirect Popup
    function tuiRedirect(link) {
        const tuiRedirect = $('#tuiRedirect');
        let tuiRedirectActive = false;
        const browser_ = navigator.userAgent.toLowerCase();

        if (tuiRedirect.length && tuiRedirectActive === false) {
            tuiRedirectActive = true;
            tuiRedirect.fadeIn(450);
    
            // disable Popup
            setTimeout(() => {
                if (browser_.indexOf('safari') > -1 && browser_.indexOf('chrome') === -1) {
                    window.location.assign(link)
                } else {
                    window.open(link, '_blank');
                }
    
                tuiRedirect.fadeOut(300);
                tuiRedirectActive = false;
            }, 1600);
        }
    }
    
    // 6m Functions
    const getFilterData = (data) => {
        const filterData = {};
        if (data.startDate) {
            filterData.startDate = data.startDate;
        }
        if (data.endDate) {
            filterData.endDate = data.endDate;
        }
        if (data.departureDate) {
            filterData.startDate = data.departureDate;
        }
        if (data.returnDate) {
            filterData.endDate = data.returnDate;
        }
        if (data.duration && data.duration.length > 0) {
            filterData.duration = data.duration[0];
        }
        if (data.lengthOfStay) {
            filterData.duration = parseInt(data.lengthOfStay , 10);
        }
        if (data.departureAirports && data.departureAirports.length > 0) {
            filterData.departureAirports = data.departureAirports.join(';')
        }
        if (data.airlines && data.airlines.length > 0) {
            filterData.airlines = data.airlines.join(';')
        }
        if (data.maxStopOver >= 0) {
            filterData.maxStopOver = data.maxStopOver.toString();
        }
        if (data.departureMinTime) {
            filterData.departureMinTime = data.departureMinTime;
        }
        if (data.departureMaxTime) {
            filterData.departureMaxTime = data.departureMaxTime;
        }
        if (data.returnMinTime) {
            filterData.returnMinTime = data.returnMinTime;
        }
        if (data.returnMaxTime) {
            filterData.returnMaxTime = data.returnMaxTime;
        }
        if (data.minPrice) {
            filterData.minPrice = data.minPrice;
        }
        if (data.maxPrice) {
            filterData.maxPrice = data.maxPrice;
        }
    
        if (data.tourOperator && data.tourOperator.length > 0 && Array.isArray(data.tourOperator)) {
            filterData.operators = data.operators.join(';');
        }
    
        if (data.tourOperator && data.tourOperator !== '') {
            filterData.operators = data.tourOperator;
        }
    
        if (data.tourOperators && data.tourOperators.length > 0 && Array.isArray(data.tourOperators)) {
            filterData.operators = data.tourOperators.join(';');
        }
    
        if (typeof(data.searchscope) !== 'undefined' && data.searchscope.hasOwnProperty && data.searchscope) {
            filterData.searchScope = data.searchscope;
        } else if (typeof(data.searchScope) !== 'undefined' && data.searchScope.hasOwnProperty && data.searchScope) {
            filterData.searchScope = data.searchScope;
        } else if (typeof(data.travelType) !== 'undefined' && data.travelType.hasOwnProperty && data.travelType === 'hotel') {
            filterData.searchScope = 'HOTEL';
        }
    
        if (data.rooms && Array.isArray(data.rooms)) {
            filterData.adults = data.rooms.map(room => room.numberOfAdults).join(',');
            filterData.childs = data.rooms.map((room) => {
                if (room.childAges && Array.isArray(room.childAges)) {
                    return room.childAges.join(';');
                }
                return '';
            }).join(',');
            if (data.rooms[0].boardTypes) {
                filterData.boardTypes = data.rooms[0].boardTypes.join(';');
            }
            if (data.rooms[0].roomTypes) {
                filterData.roomTypes = data.rooms[0].roomTypes.join(';');
            }
        }
        return filterData;
    };

    if ($('tui-banner').length) {
        addEventListener('ctaClick', (e) => {
            tuiRedirect($(e.target).attr('button-link'));
        });
    }
    
    const getPageLanguage = function() {
        if (document.documentElement && document.documentElement.lang) {
            return document.documentElement.lang.substring(0,2);
        }
        return 'de';
    };
    
    const addStaticUrlParameters = (staticUrlParameters, giataId, url) => {
        if (staticUrlParameters) {
            const parametersObject = JSON.parse(staticUrlParameters);
    
            if (parametersObject && typeof parametersObject === 'object') {
                for (let additionalParam in parametersObject) {
                    if (additionalParam.toLowerCase() === "contentid") {
                        if (!parametersObject[additionalParam].endsWith(giataId)) {
                            parametersObject[additionalParam] += '_' + giataId;
                        }
                    }
                    url.searchParams.set(additionalParam, parametersObject[additionalParam]);
                }
            }
        }
    };
    
    const generateCallToActionUrl = (plattformUrl, searchParameters, giataId, hotelName, staticUrlParameters) => {
        // lang raus weil nicht mehr genutzt
        // const lang = getPageLanguage() + '/';
        let scopePath = 'pauschalreisen';
    
        if (
            (typeof(searchParameters) !== 'undefined' && searchParameters.searchScope === 'HOTEL') ||
            (typeof(searchParameters) !== 'undefined' && searchParameters.travelType === 'hotel') ||
            (typeof(searchParameters) !== 'undefined' && searchParameters.searchscope === 'HOTEL')
        ){
            scopePath = 'hotel';
        }
        let urlString = plattformUrl + scopePath + '/suchen/angebote';
        urlString += '/' + hotelName;
        urlString += '/' + giataId;
        urlString += '/id';
        const url = new URL(urlString);
    
        let filterData = [];
        if (typeof(searchParameters) !== 'undefined') {
            filterData = getFilterData(searchParameters);
        }
    
        for (let key in filterData) {
            url.searchParams.set(key, filterData[key]);
        }
    
        addStaticUrlParameters(staticUrlParameters, giataId, url);
    
        return {giataId: giataId, url: url.href }
    }
    
    const generateCallToActionUrls = (plattformUrl, searchParameters, hotelOffers, staticUrlParameters, callback) => {
        var urls = [];
        if (hotelOffers && searchParameters) {
            for (var i = 0; i < hotelOffers.length; i++) {
                let url = generateCallToActionUrl(plattformUrl, searchParameters, hotelOffers[i].hotel.giataId, hotelOffers[i].hotel.name, staticUrlParameters)
                urls.push(url);
            }
            callback(urls);
        }
    };
    
    // Listen to Fetch
    let hotelResults = false;
    let offersResults = false;
    let resultTimeout = '';
    
    const { fetch: originalFetch } = window;
        window.fetch = async (...args) => {
            let [resource, config] = args;
            let response = await originalFetch(resource, config);
    
            if (resource === 'https://api.cloud.tui.com/hotel-offer-cards/current/search/TUICOM') {
                const payloadJson = JSON.parse(config.body).parameters;
    
                if (payloadJson.searchform) {
                    const $card__resultes__offers = $('.card__resultes__offers');
                    const $card__resultes__hotels = $('.card__resultes__hotels');
                    const $card__resultes__container = $('.card__resultes__container');
                    const groupId = payloadJson.groupId;
                    const giatas = payloadJson.giatas;
    
        
                    if (giatas.length && response.status === 200) {
                        $card__resultes__hotels.show();
                        $card__resultes__container.addClass('hotelsShow');
                        hotelResults = true;
                    } else if (groupId.length && response.status === 200) {
                        $card__resultes__offers.show();
                        $card__resultes__container.addClass('offersShow');
                        offersResults = true;
                    } else if (giatas.length && response.status === 400) {
                        $card__resultes__hotels.hide();
                        $card__resultes__container.removeClass('hotelsShow');
                        hotelResults = false;
                    } else if (groupId.length && response.status === 400) {
                        $card__resultes__offers.hide();
                        $card__resultes__container.removeClass('offersShow');
                        offersResults = false;
                    }   
        
                    resultLabel();
                }            
            }
    
        return response;
    };
    
    
    function resultLabel() {
        clearTimeout(resultTimeout);
        resultTimeout = setTimeout(() => {
            if (!offersResults && !hotelResults) {
                document.tui.searchformParameters.cta = {
                    label: 'Keine Angebote gefunden',
                };
                const searchForm = document.querySelector('tui-search-form');
                searchForm.setFormData(document.tui.searchformParameters);
                $('.card__resultes__showMore').hide();
            } else if (offersResults || hotelResults) {
                $('.card__resultes__showMore').show();
            }
        }, 500);
    }
});
